import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';


class StudioArtCenovnik extends Component {
	constructor(props){
		super(props);
		this.state = { cenovnik: [], greska:''  };
		this.ucitaniArtCenovnik = this.ucitaniArtCenovnik.bind(this);
	}
    ucitaniArtCenovnik(resp){
		console.log(resp);
    	if(resp)
            if(resp.OKERR)
        		this.setState({ cenovnik: resp.lines, greska:'' });
        	else 
        		this.setState({ greska:[resp.msg]})
        else
        	this.setState({ greska:'resp je undefined'})
    }
    formatCena(n){
        var moneyFormatter2 = new Intl.NumberFormat('sr-RS', {  minimumFractionDigits: 0 });
        return moneyFormatter2.format(parseInt(n));
    }
	componentDidMount(){
        let foto_id = this.props.foto_id;
        let data = {
            orderBy: {art_galerija_field_redosled: 'ASC'},
            whereCols:[
                { colName: 'art_galerija_field_fotografija_id', colVal: foto_id, oper:'=', logicOper: 'AND' }
            ]
        }
        ajaxCall('jinxapi/model/art_galerija/table', 'GET', data, this.ucitaniArtCenovnik);
	}
	render() {
        let cenovnik = [
            (<tr key = {-2}><td className="velicina">minijature</td></tr>)
        ];
        for(let i=0; i<this.state.cenovnik.length-2; i+=3){
            if(i==6) cenovnik.push(<tr  key = {i-1}><td className="velicina" >veliki formati</td></tr>);
            cenovnik.push(
                <tr key = {i}>
                    <td className="format">{this.state.cenovnik[i].format}</td>
                    <td>{this.formatCena(this.state.cenovnik[i].cena)}</td>
                    <td>{this.formatCena(this.state.cenovnik[i+1].cena)}</td>
                    <td>{this.formatCena(this.state.cenovnik[i+2].cena)}</td>
                </tr>
            )
        }
	    return (
            <table className="table">
                <thead>
                    <tr><th className="">prikazane cene<br />su u dinarima</th><th>canvas</th><th>alubond</th><th>forex</th></tr>
                </thead>
                <tbody>
                    {cenovnik}
                </tbody>
            </table>

        );
    }
    
}

export default StudioArtCenovnik;


