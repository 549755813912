import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';
import Parser from 'html-react-parser';

class Onama extends Component {
	constructor(){
		super();
		this.state = { podaciOnama: [], greska:''  };
		this.ucitaniPodaciOnama = this.ucitaniPodaciOnama.bind(this);
	}
    ucitaniPodaciOnama(resp){
		console.log(resp);
    	if(resp)
            if(resp.OKERR)
        		this.setState({ podaciOnama: resp.lines, greska:'' });
        	else 
        		this.setState({ greska:[resp.msg]})
        else
        	this.setState({ greska:'resp je undefined'})

	}
	componentDidMount(){
        ajaxCall('jinxapi/model/onama/table', 'GET', {
            orderBy: {onama_field_redosled: 'ASC'}
        }, this.ucitaniPodaciOnama);
    }
	render() {
        let podaciOnama = [];
		if(this.state.greska !== '')
            podaciOnama = this.state.greska;
		else
			if(this.state.podaciOnama.length > 0)
                podaciOnama = this.state.podaciOnama.map(podatakOnama => {
                    let a = (podatakOnama.redosled == 1)? "d-none d-md-block float-right p-4" : "d-none d-md-block float-left p-4";
                    let b = (podatakOnama.redosled == 1)? "" : "300";
                    let c = (podatakOnama.redosled == 1)? "" : "auto";
                    let sl = "images/site/" + podatakOnama.slika;
                    // let e = (podatakOnama.redosled == 1)? "p-3" : "p-3 pb-5";
                            return(
                                <>
                                    <h2 className="font1"><span className="">| </span> priča o <span className="text-uppercase"> {podatakOnama.naslov} </span></h2>
                                    <img src={sl} alt={podatakOnama.alt_slike} title={podatakOnama.title_slike} className="d-block d-md-none mx-auto" width={b} height={c} loading="lazy"/>
                                    <img src={sl} alt={podatakOnama.alt_slike} title={podatakOnama.title_slike} className={a}  width={b} height={c} loading="lazy"/>
                                    <p className="p-3" >{Parser(podatakOnama.tekst)}</p>
                                </>
                            )
                    });
	    return (
            <div id="teloOnama" className="container pb-5">
                {podaciOnama}
            </div>
	    );
	}
}

export default Onama;
