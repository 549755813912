import React, {Component} from 'react';
import Parser from 'html-react-parser';


class UploadPlugin extends Component {
    constructor(props){
        
        super(props);
        /*props: file-type, file-path */
        this.state = {loadPerc:'0%', form_output_display:'none', img_thumbnail: '' }
        this.submitForm = this.submitForm.bind(this);
        this.browseFotka = this.browseFotka.bind(this);
        this.changeFotka = this.changeFotka.bind(this);
        this.refFilename = React.createRef();
        this.refForma = React.createRef();
        this.refSubmitBtn = React.createRef();
    }
    browseFotka(){
        this.refFilename.current.click();
    }
    changeFotka(){
        this.refSubmitBtn.current.click();
    }
    submitForm(event){
            //this.setState({ loadPerc: '100%', form_output_display:'block' });
            event.preventDefault();
            // let refLoading, refLoadingCounter;
            let [refLoading, refLoadingCounter] = this.props.napraviLoading();

            let oData = new FormData(this.refForma.current);
            
            let xhr = new XMLHttpRequest();
            let url_start =  "/admin/";
            if(process.env.NODE_ENV !== 'production') url_start = process.env.REACT_APP_folder + "/public" + url_start;
            xhr.open("POST", url_start + "upload_file.php", true);  
            let that = this;
            xhr.upload.addEventListener("progress", function(e) {

                var pc = parseInt(100 - (e.loaded / e.total * 100));
                //that.setState({ loadPerc:  pc + "%", form_output_display:'block' });
                if(refLoading.current)
                    refLoading.current.querySelector(".progressbar").style.width = pc+"%";

            }, false);

            // xhr.onreadystatechange = () => {
            //     console.log('state: ', xhr.readyState);
            //     //**session one** 
            //     // console will print 1 when request opened
            //     // 2 when sent and response header received
            //     // 3 when body is receiving 
            //     // 4 when done
            //     //**session two**
            //     // prints 1 
            // }
            xhr.onload = function(oEvent) {
                if (this.status == 200) {
                    if(this.responseText.substr(0, 5) == 'Error'){
                        alert('ERROR:', this.responseText);
                        //that.setState({ form_output_display:'none' });
                        return;
                    }
                    //that.setState({ form_output_display:'none' });
                    //if(refLoading.current)
                    //    refLoading.current.style.display = "none";

                    let upl_name = this.responseText;
                    let str = that.refFilename.current.value;
                    let filename = str.split(/(\\|\/)/g).pop();
                    console.log(upl_name, str, filename);
                    if(that.props.dodajFotku)
                        if(typeof that.props.dodajFotku === 'function'){
                            let df = that.props.dodajFotku;
                            df(filename, upl_name, that.refFilename, refLoadingCounter);
                        }
                    
                } else {
                    console.log("Error " + this.status + " occurred uploading your file.");
                }
                
            };
            xhr.send(oData);
    }
    render(){
        let fileType="image", filePath="../images/uploaded/";

        return (
            <>
                <div style={ { width:'100%'}}>
                    <button type="button" id="custom-button" className="btn m-2  col-5 font1"
                    onClick={this.browseFotka}>
                      <i className="fa fa-camera plus" aria-hidden="true"></i> izaberi fotografiju
                    </button>
                    <button type="button" id="custom-button" className="btn m-2  col-5 font1 float-right my-red "
                    onClick={this.props.brisiSveFotke}>
                      <i className="fa fa-trash-o plus" aria-hidden="true"></i> obriši sve fotografije
                    </button>
                    
            </div>
            <form encType="multipart/form-data" name="nova_forma" className="jinx_upload_browse" style={ {width:"0px",overflow:"hidden", position:"relative" } } ref={this.refForma} 
            onSubmit={this.submitForm } >
                <input type="file" name="fileToUpload" required=""  ref={this.refFilename} onChange={this.changeFotka} />
                <input type="hidden" name="file-type" value={fileType} />
                <input type="hidden" name="broj-porudzbine" value={this.props.brojPorudzbine} />
                <input type="hidden" name="file-path" value={filePath} />
                <input type="hidden" name="samo-broj" value={Math.random()} />
                <input type="submit" value="UPLOAD" ref={this.refSubmitBtn}  />
            </form>
            {/* <input type="submit" value="UPLOAD" /> */}
            <div className="form_output" style={ {display: this.state.form_output_display } }>
                <div className="progressbar_wrapper">
                    <div className="progressbar" style={ {width:this.state.loadPerc } }  > 
                    </div>
                </div>
                <p className="wait">Please wait...</p>
            </div>
            <img src={this.state.img_thumbnail} className="img_thumbnail" />
            <iframe name="hidden-iframe" id="hidden-iframe" style={{display: "none"}}></iframe>
            </>
        );
    }
}

export default UploadPlugin;