import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';
import GalerijaStavkeRadnje from './GalerijaStavkeRadnje';
import Parser from 'html-react-parser';


class UslugeStavkeRadnje extends Component {
	constructor(props){
		super(props);
		this.state = { stavkeUsluge: [], greska:''};
		this.ucitaneStavkeUsluge = this.ucitaneStavkeUsluge.bind(this);
	}
    ucitaneStavkeUsluge(resp){
		console.log(resp);
    	if(resp)
            if(resp.OKERR){
                this.setState({ stavkeUsluge: resp.lines, greska:''});
            }else 
        		this.setState({ greska:[resp.msg]})
        else
        	this.setState({ greska:'resp je undefined'})
    }

    componentDidMount(){
        ajaxCall('jinxapi/model/deo_stavke_radnje/table', 'GET', {
            whereCols: [ { colName: 'deo_stavke_radnje_field_stavka_radnje_id', colVal: this.props.uslugaId, oper:'=', logicOper: 'AND' }],
            orderBy: {deo_stavke_radnje_field_redosled: 'ASC'}
        }
        ,this.ucitaneStavkeUsluge);
    }
	render() {
        let stavkeUsluge = []; 
		if(this.state.greska !== ''){
            stavkeUsluge = this.state.greska;
        }else
			if(this.state.stavkeUsluge.length > 0){
                stavkeUsluge = this.state.stavkeUsluge.map(u => {
                            return(
                                <>
                                    <h2 className="p-2 font1 velika ">{u.naslov}</h2>
                                    <hr/>
                                    <p className="oNecem">{Parser(u.tekst)}</p>
                                    <div><a href={"/foto-radnja-cenovnik/#" + this.props.uslugaTip} className="btn btn-outline-secondary d-block ml-auto font1 velika">Cenovnik</a></div>
                                    <GalerijaStavkeRadnje stavkaUslugeId={u.id}/>
                                </>
                            )
                    });

                }
	    return (
            // <div id="materijal" className="container ">
            <div className="container">
                {stavkeUsluge}
            </div>
	    );
	}
}

export default UslugeStavkeRadnje;
