import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';

class CenovnikRadnja extends Component {
	constructor(props){
        super(props);
        
// props je tip i treba da bude id svake tabele za cenu stavke radnje !!!!!!!!!!!!!!!!!!!!!!!!!!!!! <div id={this.props.match.params.tip}>

		this.state = { cenovnikPodaci: [], greska:''  };
		this.ucitaniPodaciCenovnik = this.ucitaniPodaciCenovnik.bind(this);
	}
    ucitaniPodaciCenovnik(resp){
		console.log(resp);
    	if(resp)
            if(resp.OKERR)
                    this.setState({ cenovnikPodaci: resp.lines, greska:'' });
                else 
                    this.setState({ greska:[resp.msg]})
        else
                this.setState({ greska:'resp je undefined'})

	}
	componentDidMount(){
        ajaxCall('jinxapi/model/cenovnik_fotografija/table', 'GET', {
            orderBy: {'cenovnik_fotografija_field_min_kol': 'ASC', 'cenovnik_fotografija_field_dimenzije': 'ASC' }
        }, this.ucitaniPodaciCenovnik);
	}
	render() {
        let cenovnikPodaci = [];
        let n1 = [];
        let nizTr = [];
		if(this.state.greska !== '')
            cenovnikPodaci = this.state.greska;
		else
			if(this.state.cenovnikPodaci.length > 0){
                let cenovnikPodaci = this.state.cenovnikPodaci;
                
                let max_kol = cenovnikPodaci.map(p => parseInt(p.max_kol));
                let nizMaxKol = [...new Set(max_kol.sort((a,b)=>(a-b)))];  // set ide da se sredi da samo razliciti budu u nizu, a ovo a- b da idu ASC
                
                let min_kol = cenovnikPodaci.map(p => parseInt(p.min_kol));
                let nizMinKol = [...new Set(min_kol)].sort((a,b)=>(a-b));
                
                let dim = cenovnikPodaci.map(p => p.dimenzije);
                let nizDim = [...new Set(dim)];
                // console.log(nizMaxKol, nizDim);
                n1 = nizDim.map(dim=>{
                    return (
                    <th scope="col" className="">{dim}cm</th>
                    // <th scope="col" className="">{(dim ==  0)? cenovnikPodaci[ind].cena + " rsd" : '/'}</th>
                    // {(cenovnikPodaci[ind].cena > 0)? cenovnikPodaci[ind].cena + " rsd" : '/'}
                    )
                });

                let ind = 0;
                for(let i = 0; i < nizMaxKol.length; i++){
                    let nizTd = [];
                    for(let j = 0; j < nizDim.length; j++){
                        nizTd.push( 
                            <td>{(cenovnikPodaci[ind].cena > 0)? cenovnikPodaci[ind].cena + " rsd" : '/'}</td>
                        );
                        ind++;
                    }
         {/* VERZIJA SA PRAZNIM POLJEM PRVE KOLONE            */}
                    // nizTr.push(
                    // <tr><td>{(nizMinKol[i] == 1)?'<' + nizMaxKol[i] +" kom": (nizMinKol[i] == 101)? '>' + nizMinKol[i] +" kom": nizMinKol[i] + " kom" + "-" + nizMaxKol[i] +" kom"}</td>{nizTd}</tr>
                    // )
     {/* VERZIJA SA kom U PRVOJ KOLONI I PRVOM REDU            */}
                    nizTr.push(
                        <tr><td>{(nizMinKol[i] == 1)?'<' + nizMaxKol[i]: (nizMinKol[i] == 101)? '>' + nizMinKol[i] : nizMinKol[i] + "-" + nizMaxKol[i]}</td>{nizTd}</tr>
                        )
                }
                
            }

	    return (
            <div id="cenovnik" className="container px-0">
                {/* <h1 className="font1"><span className="">| </span>CENOVNIK</h1> */}
                <h1 className="font1"><span className="">| </span>cenovnik <span>RADNJE</span></h1>

            <p className="">tekst(kod)</p>
                {/* <hr  className="mb-4 "/> */}
                <table id="akcije" className="table text-center col-md-8 col-lg-6">
                <thead>
                    <tr className="">
                    
        {/* VERZIJA SA PRAZNIM POLJEM PRVE KOLONE            */}
                    {/* <th scope="col" className="p-0 diag mx-auto kom">kom</th>  */}

         {/* VERZIJA SA kom U PRVOJ KOLONI I PRVOM REDU            */}            
                    <th scope="col" className="p-0 diag mx-auto kom">kom</th>
                    {n1}
                    </tr>
                </thead>
                
                 <tbody>
                    {nizTr}
                </tbody>
                </table>
                <table id="stampana-platna" style={{marginTop:"300px"}} className="table text-center col-md-8 col-lg-6">
                <thead>
                    <tr className="">
                    
        {/* VERZIJA SA PRAZNIM POLJEM PRVE KOLONE            */}
                    {/* <th scope="col" className="p-0 diag mx-auto kom">kom</th>  */}

         {/* VERZIJA SA kom U PRVOJ KOLONI I PRVOM REDU            */}            
                    <th scope="col" className="p-0 diag mx-auto kom">kom</th>
                    {n1}
                    </tr>
                </thead>
                
                 <tbody>
                    {nizTr}
                </tbody>
                </table>
                <table style={{marginTop:"300px"}} className="table text-center col-md-8 col-lg-6">
                <thead>
                    <tr className="">
                    
        {/* VERZIJA SA PRAZNIM POLJEM PRVE KOLONE            */}
                    {/* <th scope="col" className="p-0 diag mx-auto kom">kom</th>  */}

         {/* VERZIJA SA kom U PRVOJ KOLONI I PRVOM REDU            */}            
                    <th scope="col" className="p-0 diag mx-auto kom">kom</th>
                    {n1}
                    </tr>
                </thead>
                
                 <tbody>
                    {nizTr}
                </tbody>
                </table>
            </div>

	    );
	}
}

export default CenovnikRadnja;


