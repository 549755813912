import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';
import Parser from 'html-react-parser';

class Studio extends Component {
	constructor(){
		super();
		this.state = { podaciSvihGalerija: [], greska:''  };
        this.ucitaniPodaciSvihGalerija = this.ucitaniPodaciSvihGalerija.bind(this);
        // this.ceneStudio = React.createRef();
	}
	ucitaniPodaciSvihGalerija(resp){
		console.log(resp);
		// console.log(resp.lines[0].id);
    	if(resp)
            if(resp.OKERR)
        		this.setState({ podaciSvihGalerija: resp.lines, greska:'' });
        	else 
        		this.setState({ greska:[resp.msg]})
        else
                this.setState({ greska:'resp je undefined'})
    }
    componentDidMount(){
        ajaxCall('jinxapi/model/galerije/table', 'GET', {
            orderBy: {galerije_field_redosled: 'ASC'}
        }, this.ucitaniPodaciSvihGalerija);
    }


	render() {
        
        let podaciSvihGalerija = [];
		if(this.state.greska !== '')
            podaciSvihGalerija = this.state.greska;
		else
            if(this.state.podaciSvihGalerija.length > 0)
                podaciSvihGalerija = this.state.podaciSvihGalerija.map(podatakJedneGalerije => {
                        let sl = "images/site/" + podatakJedneGalerije.slika;
                        let linkZaDalje;
                        let a = (podatakJedneGalerije.tip == 'reklamna-fotografija')? "reklamna-foto" : "";
                        if(podatakJedneGalerije.tip == "cenovnik"){
                            linkZaDalje = <a href={'/foto-studio/' + podatakJedneGalerije.tip} id={podatakJedneGalerije.id} className="font1 p-2" ><span> | </span>pogledaj ponudu</a>
                        }else{
                            linkZaDalje = <a href={'/foto-studio/' + podatakJedneGalerije.tip} id={podatakJedneGalerije.id} className="font1 p-2" ><span> | </span>pogledaj galeriju</a> 
                        }
                            return(
                                <div key={podatakJedneGalerije.id} id={podatakJedneGalerije.tip} className="col-md-6 col-lg-4 my-3" >
                                    <div className="card text-right">
                                        <a href={'/foto-studio/' + podatakJedneGalerije.tip}><img src={sl} className="card-img-top img-fluid" alt={podatakJedneGalerije.alt_slike} title={podatakJedneGalerije.title_slike} loading="lazy"/></a>
                                        <div className="card-block px-4">
                                            <a href={'/foto-studio/' + podatakJedneGalerije.tip}>
                                                <h2 className='card-title font1 velika'>{podatakJedneGalerije.naslov}</h2>
                                            </a>
                                            <h6 className={a}>{podatakJedneGalerije.kraci_tekst}</h6>
                                        </div>
                                        {linkZaDalje}
                                    </div>
                                </div>
                            )
                    });
	    return (
            <div id="teloStudio" className="container pb-5">
                <h1 className="font1"><span className="">| </span> fotografski studio <span> NIKOLIĆ </span></h1>
                <p className="zaSekciju">tekst(kod)</p>
                <div className="zaSekciju ml-auto font1">
                 <span>| </span> 
                 pratite nas i na društvenim mrežama
                    <a href="https://www.instagram.com/nikolic.photography/?hl=en" target="_blank">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                    <a href="https://www.facebook.com/gorannikolic.photography/" target="_blank">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                </div>
                <div id="galerije" className="row m-2">
                    {podaciSvihGalerija}
                </div>
            </div>
	    );
	}
}

export default Studio;
