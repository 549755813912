import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';

class MeniRadnje extends Component {
	constructor(){
		super();
		this.state = { stavkeRadnje: [], greska:'' };
		this.ucitaneStavkeRadnje = this.ucitaneStavkeRadnje.bind(this);
	}
    ucitaneStavkeRadnje(resp){
		console.log(resp);
    	if(resp)
            if(resp.OKERR)
        		this.setState({ stavkeRadnje: resp.lines, greska:''  });
        	else 
        		this.setState({ greska:[resp.msg]})
        else
        	this.setState({ greska:'resp je undefined'})
    }

    componentDidMount(){
        ajaxCall('jinxapi/model/stavke_radnje/table', 'GET', {
            orderBy: {stavke_radnje_field_redosled: 'ASC'}
        }, this.ucitaneStavkeRadnje);
    }
	render() {
        let stavkeRadnje = [];
		if(this.state.greska !== '')
            stavkeRadnje = this.state.greska;
		else
			if(this.state.stavkeRadnje.length > 0)
            stavkeRadnje = this.state.stavkeRadnje.map(stavka => {
                        // let sl = "/images/" + foto.slika;
                            return(
                                <a key={stavka.tip} className="dropdown-item text-lowercase" href={"/foto-radnja/" + stavka.tip}>{stavka.naslov}</a> 
                            )
                    });
	    return (
            <div className="meniSt dropdown-menu dropitem text-left " aria-labelledby="navbarDropdown">
                {/* <a id="linkR" className="dropdown-item font-weight-bold" href="/foto-radnja">Fotografska radnja</a> */}
                {/* <a className="dropdown-item font-weight-bold text-lowercase" href="/foto-radnja-cenovnik">Cenovnik</a> */}
            {stavkeRadnje}
            </div>
	    );
	}
}

export default MeniRadnje;
