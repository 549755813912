import React, {Component} from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
// import App from '../App';
import Onama from './Onama';
import Kontakt from './Kontakt';
import Studio from './Studio';
import Galerija from './Galerija';
import MeniGalerije from './MeniGalerije';
import MeniRadnje from './MeniRadnje';
import UslugeRadnje from './UslugeRadnje';
import UslugeStudio from './UslugeStudio';
import CenovnikRadnja from './CenovnikRadnja';
import CenovnikStudio from './CenovnikStudio';
import LandingPage from './LandingPage';
import Radnja from './Radnja';
import Porucivanje from './Porucivanje';



class Headernovi extends Component{
  render(){
    return (
    <>
        <div id="nheder" className="navbar-light">
            <div id="logodiv" className="ntrojke">
                <a href="/" className="nlogo"><img src="/images/site/logo.png" alt="logo-slika" loading="lazy"/></a>
            </div>
            <div id="nmeni" className="navbar navbar-expand-md navbar-light font1 velika">
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      {/* <span id="x" className="navbar-toggler-icon" onClick={this.probaj}></span> */}
                      <span id="x" className="navbar-toggler-icon" ></span>
                  </button>


                  <div className="collapse navbar-collapse text-center bg-white" id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto">

           {/* ----------- sa stavkom ------------------          */}
                        {/* <li className="nav-item dropdown" >
                          <a className="nav-link dropdown-toggle" href="/foto-studio" id="navbarDropdown" role="button"                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                  Fotografski studio
                          </a>
                          <MeniGalerije/>
                        </li> */}
                        {/* <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="/foto-radnja" id="navbarDropdown" role="button"                 data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              Fotografska radnja
                          </a>
                          <MeniRadnje/>
                        </li> */}

              {/* ----------- bez stavke ------------------          */}
                        <li className="st nav-item dropdown " >
                          <a className="nav-link dropdown-toggle" href="/foto-studio" id="navbarDropdown">
                                  Fotografski studio
                          </a>
                          <MeniGalerije/>
                        </li>
                        <li className="st nav-item dropdown">
                          <a className="nav-link dropdown-toggle" href="/foto-radnja">
                              Fotografska radnja
                          </a>
                          <MeniRadnje/>
                        </li>

                        <li className="nav-item"><a className="nav-link " href="/o-nama">O nama</a></li>
                        <li className="nav-item"><a className="nav-link" href="/kontakt">Kontakt</a></li>
                      </ul>
                  </div>
              </div>
            {/* <div className="ntrojke">
                <a href="/foto-radnja/poruci-fotografije" className="nporuci font1"><i className="fa fa-camera plus" aria-hidden="true"></i><h4 className="">poruči</h4></a>
            </div> */}
            {/* <div className="ntrojke">
                <a href="/foto-radnja/poruci-fotografije" className="nporuci font1"><img src="/images/site/poruci-foto.png" alt="logo-slika"/></a>
            </div> */}
            {/* <div className="ntrojke">
                <a href="/foto-radnja/poruci-fotografije" className="nporuci font1"><img src="/images/site/poruci.png" alt="logo-slika"/></a>
            </div> */}
            <div className="ntrojke">
                <a href="/foto-radnja/poruci-fotografije" className="nporuci font1"><img src="/images/site/poruci-sa-foto-aparatom.png" alt="logo-slika" loading="lazy"/></a>
            </div>
            
        </div>




        <BrowserRouter>
        <Route exact path="/" component={LandingPage} />
        <Route path="/o-nama" component={Onama} />
        <Route path="/kontakt" component={Kontakt} />
        
        <Route exact path="/foto-studio" component={Studio} />
        {/* <Route path="/foto-studio/:tip" component={Galerija} /> */}
        <Route path="/foto-studio/:tip" component={UslugeStudio} />
        <Route path="/foto-studio-cenovnik" component={CenovnikStudio} />

        <Route exact path="/foto-radnja" component={Radnja} />
        <Route path="/foto-radnja/:tip" component={UslugeRadnje} />
        
        <Route exact path="/foto-radnja-cenovnik" component={CenovnikRadnja} />
        <Route path="/foto-radnja-cenovnik/:tip" component={CenovnikRadnja} />
        </BrowserRouter>
    </>
    )
  }
}

export default Headernovi;
