import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';
import Galerija from './Galerija.js';
import CenovnikStudio from './CenovnikStudio.js';
import Parser from 'html-react-parser';

class UslugeStudio extends Component {
	constructor(){
		super();
		this.state = { usluge: [], uslugaId:0, greska:''};
		this.ucitaneUsluge = this.ucitaneUsluge.bind(this);
	}
    ucitaneUsluge(resp){
		console.log(resp);
    	if(resp)
            if(resp.OKERR){
                let uslugaId = resp.lines[0].id;
                console.log("ggggggggggggggggggggggggggggg",uslugaId);
                this.setState({ usluge: resp.lines, greska:'', uslugaId:uslugaId  });
            }else 
        		this.setState({ greska:[resp.msg]})
        else
        	this.setState({ greska:'resp je undefined'})
    }

    componentDidMount(){
        let tip = this.props.match.params.tip;
        console.log("ooooooooooooooooooooooo" + tip);
        var that = this;

        ajaxCall('jinxapi/model/galerije/table', 'GET', {
            whereCols: [ { colName: 'galerije_field_tip', colVal: tip, oper:'=', logicOper: 'AND' }]
        }
        ,that.ucitaneUsluge);
    }
	render() {
        let styleH1={
            'font-size':'1.25em',
        }
        let usluge = []; 
		if(this.state.greska !== ''){
            usluge = this.state.greska;
        }else
			if(this.state.usluge.length > 0){
                usluge = this.state.usluge.map(u => {
                    if(u.tip == "cenovnik") return <CenovnikStudio />
                    if(u.naslov.toLowerCase() == 'reklamna fotografija'){
                        return(
                            <>
                                <h1 className="font1"><span >| </span>galerija <span style={styleH1}> {u.naslov}</span></h1>
                                <p className="p-4">{u.duzi_tekst}</p>
                                {/* {(u.tip == "cenovnik")? <CenovnikStudio /> : <Galerija uslugaId={this.state.uslugaId} uslugaTip={this.props.match.params.tip}/>} */}
                                <Galerija uslugaId={this.state.uslugaId} uslugaTip={this.props.match.params.tip}/>
                            </>
                        )
                    }
                    else{
                        return(
                            <>
                                <h1 className="font1"><span >| </span>galerija <span> {u.naslov}</span></h1>
                                <p className="p-4">{u.duzi_tekst}</p>
                                <Galerija uslugaId={this.state.uslugaId} uslugaTip={this.props.match.params.tip}/>
                            </>
                        )
                    }
                })
            };
	    return (
            <div id="" className="container materijal">
                {usluge}
            </div>
	    );
	}
}

export default UslugeStudio;
