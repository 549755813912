import React from 'react';

function LandingPage(){
    return (
      <>
        <div id="landing-page" >

        </div>

      </>
    );
}

export default LandingPage;
