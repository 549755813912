import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';
import Parser from 'html-react-parser';


class Radnja extends Component {
	constructor(){
		super();
		this.state = { stavkeRadnje: [], greska:''  };
		this.ucitaneStavkeRadnje = this.ucitaneStavkeRadnje.bind(this);
	}
	ucitaneStavkeRadnje(resp){
		console.log(resp);
		// console.log(resp.lines[0].id);
    	if(resp)
            if(resp.OKERR)
        		this.setState({ stavkeRadnje: resp.lines, greska:'' });
        	else 
        		this.setState({ greska:[resp.msg]})
        else
                this.setState({ greska:'resp je undefined'})
    }
    componentDidMount(){
        ajaxCall('jinxapi/model/stavke_radnje/table', 'GET', {
            orderBy: {stavke_radnje_field_redosled: 'ASC'}
        }, this.ucitaneStavkeRadnje);
    }

	render() {
        
        let stavkeRadnje = [];
		if(this.state.greska !== '')
            stavkeRadnje = this.state.greska;
		else
			if(this.state.stavkeRadnje.length > 0)
                stavkeRadnje = this.state.stavkeRadnje.map(stavka => {
                    let sl = "images/site/" + stavka.slika;

                    return(
                        <div key={stavka.id} className="col-md-6 col-lg-4 my-3" >
                            <div className="card text-right">
                                <a href={'/foto-radnja/' + stavka.tip} id={stavka.id}><img src={sl} className="card-img-top img-fluid" alt={stavka.tip} title={stavka.naslov} loading="lazy"/></a>
                                <div className="card-block px-4">
                                    <a href={'/foto-radnja/' + stavka.tip} id={stavka.id}><h2 className="card-title font1 velika">{stavka.naslov}</h2></a>
                                    <h6 className="">{stavka.kraci_tekst}</h6>
                                    {/* <p className="card-text">{stavka.kraci_tekst}</p> */}
                                </div>
                                {/* <hr/> ako hocu sa linikom iznad*/}
                                <a href={'/foto-radnja/' + stavka.tip} id={stavka.id} className="font1 p-2 "><span> | </span>pogledaj ponudu</a>
                            </div>
                         </div>

                       )
                    });
	    return (
            <div id="teloRadnja" className="container pb-5">
                <h1 className="font1"><span className="">| </span> fotografska radnja <span> NIKOLIĆ </span></h1>
                <p>tekst(kod)</p>
                <div className="zaSekciju ml-auto font1">
                 <span>| </span> 
                 pratite nas i na društvenim mrežama
                    <a href="https://www.instagram.com/nikolic.photography/?hl=en" target="_blank">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                    <a href="https://www.facebook.com/gorannikolic.photography/" target="_blank">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                </div>
                <div id="sekcijeRadnje" className="row m-2">
                    {stavkeRadnje}
                </div>
            </div>
	    );
	}
}

export default Radnja;
