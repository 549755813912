import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';
import Parser from 'html-react-parser';

class Kontakt extends Component {
	constructor(){
		super();
		this.state = { podaciKontakt: [], greska:''  };
		this.ucitaniPodaciKontakt = this.ucitaniPodaciKontakt.bind(this);
	}
    ucitaniPodaciKontakt(resp){
		console.log(resp);
    	if(resp)
            if(resp.OKERR)
        		this.setState({ podaciKontakt: resp.lines, greska:'' });
        	else 
        		this.setState({ greska:[resp.msg]})
        else
        	this.setState({ greska:'resp je undefined'})
	}
	componentDidMount(){
        ajaxCall('jinxapi/model/site/table', 'GET', {}, this.ucitaniPodaciKontakt);;
	}
	render() {
        let podaciKontakt = (this.state.podaciKontakt.length > 0)? this.state.podaciKontakt[0] : {radnja_mail: 'jok'};
        // let podaciKontakt = [];
		// if(this.state.greska !== '')
            // podaciKontakt = this.state.greska;
		// else
			// if(this.state.podaciKontakt.length > 0)
                // podaciKontakt = this.state.podaciKontakt.map(podatakKontakt => {
                    // return(
                    // )
                // });
	    return (
            <div id="teloKontakt" className="container pb-5">
                <h1 className="font1"><span className="">| </span> kontaktirajte <span> NAS </span></h1>
                <p className="">
                tekst(kod)
                </p>
                <form className="col-12 col-md-8 py-4 m-auto">
                    <div className="form-group py-2 font1">
                        <input type="text" className="form-control " id="SenderName" name="SenderName" placeholder="Ime i prezime" />
                        <input type="email" className="form-control " id="exampleFormControlInput1" placeholder="e-mail" />
                    </div>
                    <div className="form-group py-2 font1">
                        <label htmlFor="exampleFormControlTextarea1">Postavi pitanje</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="tekst pitanja"></textarea>
                    </div>
                    <button type="submit" className="btn d-block m-auto font1" >Pošaljite poruku</button>
                </form>
                
                <div className="row justify-content-around pt-5">
                    <div className="card col-12 col-md-5 p-4" >
                        <div className="card-body ">
                            <h2 className="card-title font1"><span> | </span>fotografski studio <span>NIKOLIĆ</span></h2>
                            <hr className="mb-4 linijica" />
                            <h4 className="font1 p-2">
                            <a href="https://www.google.com/maps/place/Fotografski+studio+NIKOLI%C4%86/@44.8691084,20.6429679,15z/data=!4m5!3m4!1s0x0:0xeb9dea76a3da3a25!8m2!3d44.8691084!4d20.6429679" target="_blank"><i className="fa fa-map-marker" aria-hidden="true"></i></a>
                            <a href="https://www.google.com/maps/place/Fotografski+studio+NIKOLI%C4%86/@44.8691084,20.6429679,15z/data=!4m5!3m4!1s0x0:0xeb9dea76a3da3a25!8m2!3d44.8691084!4d20.6429679" target="_blank">{podaciKontakt.studio_adresa}, {podaciKontakt.studio_grad}</a></h4>
                            <h4 className="font1 p-2 text-right"><a href="viber://chat?number=+381655329671" className=""><i className="fa fa-phone" aria-hidden="true"></i></a><a href="viber://chat?number=+381655329671" className="">ivana tel proba viber</a></h4>
                            {/* <h4 className="font1 p-2 text-right"><a href="viber://chat?number=+381642631999" className=""><i className="fa fa-phone" aria-hidden="true"></i></a><a href="viber://chat?number=+381642631999" className="">ivana tel proba viber</a></h4> */}
                            <h4 className="font1 p-2 text-right"><a href={"mailto:" + podaciKontakt.studio_mail}><i className="fa fa-envelope-o" aria-hidden="true"></i></a><a href={"mailto:" + podaciKontakt.studio_mail}>{podaciKontakt.studio_mail}</a></h4>
                            <h4 className="font1 p-2 text-right"><i className="fa fa-clock-o" aria-hidden="true"></i>{podaciKontakt.studio_vreme}</h4>
                        </div>
                    </div>
                    <div className="card col-12 col-md-5 p-4" >
                        <div className="card-body ">
                        <h2 className="card-title font1"><span> | </span>fotografska radnja <span>NIKOLIĆ</span></h2>
                            <hr className="mb-4 linijica" />
                            <h4 className="font1 p-2 text-right">
                                <a href="https://www.google.com/maps/place/Fotografska+radnja+Nikoli%C4%87+Photo+Industry/@44.8708845,20.6421846,15z/data=!4m5!3m4!1s0x0:0x68468c6d1aa8872d!8m2!3d44.8708845!4d20.6421846" target="_blank"><i className="fa fa-map-marker" aria-hidden="true"></i></a>
                                <a href="https://www.google.com/maps/place/Fotografska+radnja+Nikoli%C4%87+Photo+Industry/@44.8708845,20.6421846,15z/data=!4m5!3m4!1s0x0:0x68468c6d1aa8872d!8m2!3d44.8708845!4d20.6421846" target="_blank">{podaciKontakt.radnja_adresa}, {podaciKontakt.radnja_grad}</a>
                            </h4>
                            <h4 className="font1 p-2 text-right">
                                <a href="viber://chat?number=+381655329671" className=""><i className="fa fa-phone" aria-hidden="true"></i></a>
                                <a href="viber://chat?number=+381655329671" className="">ivana tel proba viber</a>
                            </h4>
                            {/* <h4 className="font1 p-2 text-right"><a href="viber://chat?number=+381642631999" className=""><i className="fa fa-phone" aria-hidden="true"></i></a><a href="viber://chat?number=+381642631999" className="">ivana tel proba viber</a></h4> */}
                            <h4 className="font1 p-2 text-right">
                                <a href={"mailto:" + podaciKontakt.radnja_mail}><i className="fa fa-envelope-o" aria-hidden="true"></i></a>
                                <a href={"mailto:" + podaciKontakt.radnja_mail}>{podaciKontakt.radnja_mail}</a>
                            </h4>
                            <h4 className="font1 p-2 text-right"><i className="fa fa-clock-o" aria-hidden="true"></i>{podaciKontakt.radnja_vreme}</h4>
                        </div>
                    </div>
                </div>
            </div>

	    );
	}
}

export default Kontakt;
