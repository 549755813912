import React, {Component} from 'react';
import UploadPlugin from './UploadPlugin.js';
import {ajaxCall} from './ajaxCall.js';
import Parser from 'html-react-parser';


class Porucivanje extends Component {
    constructor(){
      super();
      this.state = { porucivanjeTip : "", fotografije:[], tipPapira:'mat', format:'10x15', badInputFields: {}, cenovnik:[], brojPorudzbine:0, refLoadingCounter:0  };
      this.naruciClick	=this.naruciClick.bind(this);
      this.radioPorucivanjeClick = this.radioPorucivanjeClick.bind(this);
      this.dodajFotku = this.dodajFotku.bind(this);
      this.brisiSveFotke = this.brisiSveFotke.bind(this);
      this.tipPapiraClick = this.tipPapiraClick.bind(this);
      this.formatClick = this.formatClick.bind(this);
      this.photo1Click = this.photo1Click.bind(this);
      this.potvrdiPorudzbinu = this.potvrdiPorudzbinu.bind(this);
      this.napraviPorudzbinuUBazi = this.napraviPorudzbinuUBazi.bind(this);
      this.srediFotkeFoldera = this.srediFotkeFoldera.bind(this);
      this.napraviLoading = this.napraviLoading.bind(this);
      this.formaPoruci = React.createRef();
      this.dugmeNaruci = React.createRef();
    }

    componentDidMount(){
      let fotkeStr = localStorage.getItem('fotografije');
      if(fotkeStr) {
        let fotke = JSON.parse(fotkeStr);
        this.setState({fotografije: fotke});
      };
      let brojPorudzbine = localStorage.getItem('brojPorudzbine');
      if(brojPorudzbine && brojPorudzbine > 0){
          console.log('napraviPorudzbinu: ',brojPorudzbine);
          this.setState({brojPorudzbine: parseInt(brojPorudzbine)});
      }else{
          this.napraviPorudzbinuUBazi();
      }
      let that = this;
      ajaxCall('jinxapi/model/cenovnik_fotografija/table', 'GET', {}, function(resp){
          if(resp && resp.lines)
              that.setState({ 'cenovnik': resp.lines} );
      });
    }
    snimiFotke(fotke, c){
      console.log('SNIMI FOTKE', c)
      this.setState({ fotografije:fotke });
      if(c == undefined){
          let fotkeBez = fotke.filter(fotka => fotka.loading == false);
          localStorage.setItem('fotografije', JSON.stringify(fotkeBez));
      }
    }
    tipPapiraClick(e){
      this.setState({ tipPapira: e.target.value });
    }
    formatClick(e){
      this.setState({ format: e.target.value });
    }
    photo1Click(upl, opt, val){
        console.log(upl, opt, val);
        let fotke = this.state.fotografije;
        let fotka = fotke.filter(fotka =>fotka.upl == upl)[0];
        if(opt == 'kol'){
          val = (val=='+1')? fotka.kol+1 : fotka.kol-1;
          if(val <= 0){
            if (window.confirm("Sigurno želite da obrišete fotografiju?")){
              fotke = fotke.filter(fotka =>fotka.upl !== upl);
            }else
              val = 1;
          }
        }
        fotka[opt] = val;
        this.postaviSveCene(fotke);
    }
    postaviSveCene(fotke){
        //let fotke = this.state.fotografije;
        console.log('AFTER FOTKE', fotke)
        let uku_kol = fotke.reduce((s,f)=>s+f.kol, 0);
        fotke = fotke.map(fotka => {
            let cene = this.state.cenovnik.find(red => {
              return (red.dimenzije==fotka.format && red.min_kol<=uku_kol && red.max_kol>=uku_kol && red.cena>0)
            } );
            if(cene)
              fotka.cena = cene.cena;
            return fotka;
        });
        this.snimiFotke(fotke);        
    }
    radioPorucivanjeClick(e){
      this.setState({porucivanjeTip:e.target.value, badInputFields:{} });
    }
    naruciClick(){
        if(this.state.porucivanjeTip == ''){
              alert('Popunite tip Preuzimanja');
              return;
        }
        if(this.state.fotografije.length == 0){
          alert('Niste postavili nijednu fotografiju!');
          return;
        }
        this.formaPoruci.current.style.display = "block";
        this.dugmeNaruci.current.style.display = "none";
    }
    potvrdiPorudzbinu(e){
        e.preventDefault();
        let provera = this.proveraInputNaruci();
        if(provera.greska == true) return;
        let v = provera.vals;
        //upis u bazu - kupac i sve fotke
        this.snimiSvePodatke(v);
        //obavestenje
        //povratak u meni
    }
    proveraInputNaruci(polje){
      let newStateBadFields = Object.assign({}, this.state.badInputFields), greska = false, vals = {};
      if(polje){
          let v = this.formaPoruci.current.querySelector('input[name="'+polje+'"]');
          if(v.value == '') newStateBadFields[polje] = 'badinput';
          else newStateBadFields[polje] = '';
      }else{
          //provera svega
          let names = (this.state.porucivanjeTip == 'posta')? ["ime", "prezime", "email", "grad", "post_broj", "adresa", "telefon"] : ["ime", "prezime", "email", "telefon"] ;
          //da li su prazni
          for(let i=0;i<names.length; i++){
            let v = this.formaPoruci.current.querySelector('input[name="'+names[i]+'"]');
            if(v.value == ''){
              //v.classList.addClass('badinput');
              newStateBadFields[names[i]] = 'badinput';
              greska = true;
            }else{
              vals[names[i]] = v.value; 
              newStateBadFields[names[i]] = '';
            }
          }
      }
      this.setState({ badInputFields: newStateBadFields });
      return {greska:greska, vals:vals};
    }



    napraviLoading(){
      let refLoading = React.createRef();
      let refLoadingCounter = this.state.refLoadingCounter + 1;
      let fotka = { loading:true, loadingCounter: refLoadingCounter, ref:refLoading, kol:1, tip: this.state.tipPapira, format:this.state.format  };
      this.setState({refLoadingCounter: refLoadingCounter});
      let fotke = [...this.state.fotografije, fotka];
      this.snimiFotke(fotke, false);    
      return [refLoading, refLoadingCounter] ;    
    }

    dodajFotku(filename, upl, imgEl, refLoadingCounter){
        let fotke = this.state.fotografije;
        let ima = false;
        for(let a in fotke){
            if(fotke[a].loadingCounter == refLoadingCounter){
              console.log("NASAO");
              fotke[a] = {img:filename, upl:upl, kol:1, tip: this.state.tipPapira, format:this.state.format, loading:false };
              ima = true;
            }else{
            }
        }
        if(ima == false)
            fotke.push({img:filename, upl:upl, kol:1, tip: this.state.tipPapira, format:this.state.format, loading:false });

          this.postaviSveCene(fotke);
          imgEl.current.value = "";
    }
    brisiSveFotke(){
        
        this.snimiFotke([]); 
    }


    napraviPorudzbinuUBazi(){
      let that = this;
      let data1 = {cols: { 'porudzbine_field_id':''} };
      ajaxCall('jinxapi/model/porudzbine/input', 'POST', data1, 
          function(a){ 
            //console.log('napraviPorudzbinuUBazi: ', JSON.stringify(a));
            if(a.OKERR == true){
                localStorage.setItem('brojPorudzbine',a.insertedLine.id);
                that.setState({brojPorudzbine:a.insertedLine.id});
            }
      });  
}

    snimiSvePodatke(vals){
          let fotke = [...this.state.fotografije];
      
          let sqlQueries = [];
          let data1 = {};
          for(let ime in vals)
              data1["porudzbine_field_"+ime] = vals[ime];                
          data1["porudzbine_field_tip_preuzimanja"] = this.state.porucivanjeTip;
          data1["porudzbine_field_tip_papira"] = this.state.tipPapira;
          let uku_cena = fotke.reduce( (s,fotka)=> s+fotka.cena*fotka.kol, 0);
          data1["porudzbine_field_uku_cena"] = uku_cena;
          //  IMA JOS
          sqlQueries.push({path:'model/porudzbine/:'+this.state.brojPorudzbine, cols:data1, jinxMethod:'PUT' });
          // sqlQueries.push({path:'model/porudzbine/input', cols:data1, jinxMethod:'POST' });
          
          for(let i=0; i<fotke.length; i++){
              let data2 = {
                "stavke_porudzbine_field_porudzbina_id": this.state.brojPorudzbine, //'prevInsert[0][id]',
                // "stavke_porudzbine_field_porudzbina_id": 'prevInsert[0][id]',
                "stavke_porudzbine_field_slika": fotke[i].upl,
                "stavke_porudzbine_field_kolicina": fotke[i].kol,
                "stavke_porudzbine_field_dimenzije": fotke[i].format,
                "stavke_porudzbine_field_cena": fotke[i].cena,
                "stavke_porudzbine_field_uku_cena": fotke[i].cena * fotke[i].kol
              };
              sqlQueries.push({path:'model/stavke_porudzbine/input', cols:data2, jinxMethod:'POST' });
          }
          
          let that = this;
          ajaxCall('server/jinxapi/sqlTrans/', 'POST', { sqlQueries:sqlQueries}, 
              function(a){ 
                console.log(JSON.stringify(a));
                if(a.OKERR == true){
                    that.srediFotkeFoldera();
                    
                }
              }
          );
    }

    srediFotkeFoldera(){
      let that = this;
      let v = this.formaPoruci.current.querySelector('input[name="email"]').value;
      ajaxCall('srediFotke.php', "GET", {fotke:this.state.fotografije, brojPorudzbine: this.state.brojPorudzbine, mail:v }, function(a){
          if(a.OKERR === true){
            that.brisiSveFotke();
            localStorage.setItem('brojPorudzbine', 0);
            that.setState({ brojPorudzbine: 0 });
            alert('Hvala vam što ste naručili izradu fotografija kod nas!');
            //window.location.href = "/";
            that.setState({mailOdg:a.msg});
          }else{
            alert(a.msg);
          }
      });
    }


    render() {

          let fotografije = [];
          let sveDim = ["10x15", "13x18", "15x21", "20x30"];
          let formatRadio = sveDim.map(dim=>{
            return(
              <>
              <input type="radio" name="format" value={dim} checked={this.state.format === dim} onChange={this.formatClick} key={"i"+dim} key={dim+"dim"} /> {dim.substr(0,2)} x {dim.substr(3,2)} <br />
              </>
            )
          })
          let formatSelect = (foto) => {
            return (
            <select name="dimenzije" onChange={(e)=>this.photo1Click(foto.upl, 'format', e.target.value)} defaultValue={foto.format} key={"sel"+foto.upl} >
              {sveDim.map(dim=>{
                return(
                  <option value={dim} key={"o"+dim} > {dim.substr(0,2)} x {dim.substr(3,2)} </option> 
                )
              })
              }
            </select>
          )};
          let ukCena = 0;
          if(this.state.fotografije.length > 0){
            ukCena = this.state.fotografije.reduce((s,f) => s+(f.cena*f.kol), 0);
            fotografije = this.state.fotografije.map(foto => {
                      let loadingBar = (foto.loading == true)? (
                        <div className="form_output" ref={ foto.ref } style={{display:'block'}}>
                          <div className="progressbar_wrapper">
                              <div className="progressbar" style={ {width:0 } }  > 
                              </div>
                          </div>
                          <p className="wait">Please wait...</p>
                        </div>
                      ) : (<span className="imeSl" >{foto.img} - {(foto.refLoadingCounter)? foto.refLoadingCounter: ''}</span>);
                      return(
                          // <div className="card col-6 col-sm-4 col-md-6 col-lg-3" key={foto.upl} >
                          // <div className="card col-6 col-sm-4 " key={foto.upl} >
                          <div className="card mb-2" key={foto.upl} >
                              {loadingBar}
                              <div className="card-img-top mx-auto " style={ {backgroundImage: "url('"+foto.upl+"')",backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition:"center", width:"120px",height:"120px" } }></div>
                              {/* <img className="card-img-top" src={foto.upl} alt="Card image cap" /> */}
                              <div className="card-body">
                                <p className="text-center">
                                  <i className="fa fa-minus-circle" aria-hidden="true" onClick={(e)=>this.photo1Click(foto.upl, 'kol', '-1')}></i> 
                                    {" "+foto.kol+" "} 
                                  <i className="fa fa-plus-circle" aria-hidden="true" onClick={(e)=>this.photo1Click(foto.upl, 'kol', '+1')}></i></p>
                                <p className="text-center">
                                  {formatSelect(foto)}
                                </p>
                                {/* <p className="text-center">{foto.tip}</p> */}
                                <p className="text-center cenaP">{((isNaN(foto.cena * foto.kol))? 0 : foto.cena * foto.kol) + " rsd"}</p>
                              </div>
                          </div>
                      )
              }); 
          }
          


        
        return (
          // <div id="porucivanje" className="container ">
          <div id="porucivanje" className="">
              {/* <hr className="mb-4 " /> */}
              {/* <h3 className="display-4 font1 text-center">Izradite fotografije online!</h3> */}
              <h1 className="font1"><span className="">| </span>poručite <span>FOTOGRAFIJE</span></h1>
              {/* <p className="col-12 mx-auto lead py-4">ovde ide teks o izboru za porucivanje. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius, vel.lorem20. Lorem, ipsum. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Debitis atque quis aspernatur adipisci aperiam odit soluta, aliquam temporibus sunt porro!
              {this.state.mailOdg}
              </p> */}
              <p className="">{Parser('<p>ZOKA Jednostavno odaberite fotografije klikom na dugme</p><p>Ukoliko želite da Vam sve izabrane fotografije budu istih dimenzija, odaberite ovde neku od ponuđenih. Dimenzije mozete promeniti za odredjene fotografije odabirom neke druge dimenzije ponudjene ispod same fotografije</p>')}
              {this.state.mailOdg}
              </p>
              <a id="reg" href="#regularno-porucivanje" className="btn btn-block mx-auto mt-5 col-12 col-md-7 col-xl-5 font1 poruciFoto py-2 mb-3"> Poručite fotografije <span className="pl-2"><img src="/images/site/down.png" loading="lazy"></img></span></a>
              <a id="we" href="https://wetransfer.com/" target="_blank"  className="btn btn-block mx-auto col-12 col-md-7 col-xl-5 font1 poruciFoto py-2 mb-3"> Poručite putem WeTransfer-a <span className="pl-2"><img src="/images/site/we.png" loading="lazy"></img></span></a>
              <a id="viber" href="viber://chat?number=%2B655329671" className="btn btn-block mx-auto col-12 col-md-7 col-xl-5 font1 poruciFoto py-2 mb-3"> Poručite putem Viber-a <span className="pl-2"><img src="/images/site/viber.png" loading="lazy"></img></span></a>
              
              <div id="regularno-porucivanje" className="row" >
                {/* <p className="p-3 mt-5">Jednostavno odaberite fotografije klikom na dugme</p>
                <p className="p-3">Ukoliko želite da Vam sve izabrane fotografije budu istih dimenzija, odaberite ovde neku od ponuđenih. Dimenzije mozete promeniti za odredjene fotografije odabirom neke druge dimenzije ponudjene ispod same fotografije</p> */}



                <div className="row col-md-5 col-lg-4 align-self-start p-4 mx-auto">
                  <div className="card col-12" >
                    <div className="card-body">
                      {/* <h5 className="card-title">TIP PAPIRA</h5> */}
                      <h5 className="card-title font1"><span className="">| </span> tip papira</h5>
                      <hr className="linijica mb-4" />
                      <p className="card-text">
                        <input type="radio" name="tip-papira" value="mat" checked={this.state.tipPapira === "mat"} onChange={this.tipPapiraClick} /> mat <br /> 
                        <input type="radio" name="tip-papira" value="sjajni" checked={this.state.tipPapira === "sjajni"} onChange={this.tipPapiraClick} /> sjajni
                      </p>
                    </div>
                  </div>
                  <div className="card col-12" >
                    <div className="card-body">
                      {/* <h5 className="card-title">DIMENZIJE / cm</h5> */}
                      <h5 className="card-title font1"><span className="">| </span> dimenzije / cm</h5>
                      <hr className="linijica mb-4" />
                      <p className="card-text"> 
                        {formatRadio }
                      </p>
                    </div>
                  </div>
                  <div className="card col-12" >
                    <div className="card-body">
                      {/* <h5 className="card-title">PREUZIMANJE</h5> */}
                      <h5 className="card-title font1"><span className="">| </span> preuzimanje</h5>
                      <hr className="linijica mb-4" />
                      <p className="card-text" id="isp">            
                        <input type="radio" name="isporuka" value="licno" onClick={(e)=>this.radioPorucivanjeClick(e)}  /> lično preuzimanje <br/>
                        <input type="radio" name="isporuka" value="posta" onClick={(e)=>this.radioPorucivanjeClick(e)} /> brza pošta
                      </p>
                    </div>
                  </div>
                </div>







                <div id="okvir" className="row col-md-7 col-lg-8 p-1 mb-3 mx-auto" >
                  
                  <UploadPlugin refLoading={this.state.refLoading} dodajFotku={this.dodajFotku} brisiSveFotke={this.brisiSveFotke} brojPorudzbine={this.state.brojPorudzbine} napraviLoading={this.napraviLoading} />
                  
                  <div className="row mx-auto">
                    {fotografije}
                  </div>
                </div>

                <div className="ukupno col-12  mx-auto mb-2 text-center">Ukupna cena porudžbine je {ukCena} rsd</div>
                <button id="poruci-btn" onClick={this.naruciClick} className="btn mb-3 py-2 btn-block col-4 font1 mx-auto" ref={this.dugmeNaruci}> Naruči  </button>
                
                <div id="preuzimanje" className="preuzimanje row mx-auto col-12" ref={this.formaPoruci} >
                  <form id="licno-preuzimanje" className="col-12 col-md-10 col-lg-8 mx-auto">
                    <div className="form-group">
                      <input onChange={this.proveraInputNaruci.bind(this,"ime")} className={"form-control "+ this.state.badInputFields.ime} placeholder="Ime" name="ime" />
                      <input onChange={this.proveraInputNaruci.bind(this,"prezime")} className={"form-control "+ this.state.badInputFields.prezime} placeholder="Prezime" name="prezime" />
                      <input onChange={this.proveraInputNaruci.bind(this,"email")} type="email" className={"form-control "+ this.state.badInputFields.email} aria-describedby="emailHelp" placeholder="E-mail" name="email" />
                      <div id="posta-preuzimanje" className={((this.state.porucivanjeTip == "posta")? ' prikazi': '') }>
                        <input onChange={this.proveraInputNaruci.bind(this,"grad")} className={"form-control "+ this.state.badInputFields.grad} placeholder="Grad" name="grad" />
                        <input onChange={this.proveraInputNaruci.bind(this,"post_broj")} className={"form-control "+ this.state.badInputFields.post_broj} placeholder="Poštanski broj" name="post_broj" />
                        <input onChange={this.proveraInputNaruci.bind(this,"adresa")} className={"form-control "+ this.state.badInputFields.adresa} placeholder="Adresa" name="adresa" />
                      </div>
                      <input onChange={this.proveraInputNaruci.bind(this,"telefon")} className={"form-control "+ this.state.badInputFields.telefon} placeholder="Broj telefona" name="telefon" />
                    </div>
                    <button type="submit" className="btn mb-3 py-2 btn-block col-12 col-md-5 font1 mx-auto" onClick={this.potvrdiPorudzbinu}>Potvrdi porudžbinu</button>
                  </form>
                </div>
              </div>

            </div>
        );
    
    }
}

export default Porucivanje;
