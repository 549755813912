import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';
// import StudioArt from './StudioArt';
import StudioArtCenovnik from './StudioArtCenovnik';
import Parser from 'html-react-parser';


class Galerija extends Component {
	constructor(props){
		super(props);
		this.state = { fotografije: [], nizId_fotografija:[], greska:'', ozn_foto_ind: -1 };
		this.ucitaneFotografije = this.ucitaneFotografije.bind(this);
        this.slikaClick = this.slikaClick.bind(this);
        this.zatvoriSlider = this.zatvoriSlider.bind(this);
        this.promeniSlikuDesno = this.promeniSlikuDesno.bind(this);
        this.promeniSlikuLevo = this.promeniSlikuLevo.bind(this);
        this.artG = React.createRef();
        this.masonryf = React.createRef();
	}
    ucitaneFotografije(resp){
		console.log(resp);
    	if(resp)
            if(resp.OKERR){
                let nizId_fotografija = resp.lines.map(foto => foto.id);
                //console.log("ggggggggggggggggggggggggggggg"+nizId_fotografija)
                this.setState({ fotografije: resp.lines, greska:'', nizId_fotografija:nizId_fotografija  });
            }else 
        		this.setState({ greska:[resp.msg]})
        else
        	this.setState({ greska:'resp je undefined'})
    }
    slikaClick(id){
        let ind = this.state.nizId_fotografija.indexOf(id);
        this.setState( { ozn_foto_ind: ind});
    }
    zatvoriSlider(){
        this.setState( { ozn_foto_ind: -1});
    }
    promeniSlikuDesno(e){
        e.stopPropagation();
        let ozn_foto_ind = parseInt(this.state.ozn_foto_ind) + 1;
        if(ozn_foto_ind >= this.state.nizId_fotografija.length){
            ozn_foto_ind = 0;
        }
        console.log('oooooooooooooooooooooozn_foto_ind: ', ozn_foto_ind);
        this.setState( { ozn_foto_ind: ozn_foto_ind});
    }
    promeniSlikuLevo(e){
        e.stopPropagation();
        let ozn_foto_ind = parseInt(this.state.ozn_foto_ind) - 1;
        if(ozn_foto_ind < 0){
            ozn_foto_ind = this.state.nizId_fotografija.length-1;
        }
        console.log('oooooooooooooooooooooozn_foto_ind: ', ozn_foto_ind);
        this.setState( { ozn_foto_ind: ozn_foto_ind});
    }
    componentDidMount(){
        let tip = this.props.uslugaTip;
        console.log("ooooooooooooooooooooooo" + tip);
        var that = this;

        ajaxCall('jinxapi/model/galerije/table', 'GET', {
            selCols:['galerije_field_id'],  //ako hocemo da vrati samo npr kolonu jednu, a ne ceo red, zbog brzine
            whereCols: [ { colName: 'galerije_field_tip', colVal: tip, oper:'=', logicOper: 'AND' }]
            // colVal je sa cim ga uporedjujemo, a to je ovde let tip
        }, function(resp){
            let id = resp.lines[0].id;        // sa ovim posle uporedjujemo
            ajaxCall('jinxapi/model/fotografije/table', 'GET', {
                whereCols: [ { colName: 'fotografije_field_galerija_id', colVal: id, oper:'=', logicOper: 'AND' }]
            },that.ucitaneFotografije);
        
        });

    }
	render() {
        let fotografije = [], slider_fotografije = []; 
		if(this.state.greska !== ''){
            fotografije = this.state.greska;
        }else
			if(this.state.fotografije.length > 0){
                fotografije = this.state.fotografije.map(foto => {
                    let sl = "/images/studio/" + foto.slika;
                        if(this.props.uslugaTip == "art"){
                            this.artG.current.style.display = "block";
                            this.masonryf.current.style.display = "none";
                            return(
                                <>
                                    <h2 className="font1 velika text-left">{foto.naslov}</h2>
                                    <hr />
                                    {/* ovo title_slike je ustvari opis slike iz art galerije koji ide ispod linije sa naslovom */}
                                    <p className="lead text-right">{foto.title_slike}</p>
                                    <div key={foto.id} className="artJedan"> 
                                        <div>
                                            <img src={sl} onClick={()=>this.slikaClick(foto.id) } loading="lazy"/>
                                        </div>
                                    <div className="artInfo">
                                        <StudioArtCenovnik foto_id={foto.id} />
                                    </div>
                                </div>
                                </>
                            )
                    }else
                        return(
                            <>
                            <div key={foto.id} className="masonryblocks"> 
                                <img src={sl} onClick={()=>this.slikaClick(foto.id) } loading="lazy"/>
                            </div>
                            </>
                        )
                });
                slider_fotografije = this.state.fotografije.map(foto => {
                    let sl = "/images/studio/" + foto.slika;
                        return(
                            <div key={"sl_"+foto.id} style={ {backgroundImage: "url('"+sl+"')"} } 
                                className= {(foto.id == this.state.nizId_fotografija[this.state.ozn_foto_ind])? 'prikazi' : '' } 
                                onClick = {(e)=>this.promeniSlikuDesno(e)}
                                > 
                            </div>
                        )
                });
            }
            let naslov = this.props.uslugaTip;
            if(naslov == "vencanja"){
                naslov = "VENČANJA";
            }

	    return (
            <>
            <div id="artG" ref={this.artG}>
                {fotografije}
            </div>
            <div className="masonryfolder" ref={this.masonryf} >
                {fotografije}
            </div>

            <div className={"slider_galerija "+((this.state.ozn_foto_ind >= 0)? 'prikazi' : '') }>
                <span className="closeX">
                    <i className="fa fa-times-circle" aria-hidden="true" onClick={this.zatvoriSlider}></i>
                </span>
                {slider_fotografije}
                <span className="strelicaLevo">
                    <i className="fa fa-angle-left" aria-hidden="true" onClick = {(e)=>this.promeniSlikuLevo(e)}></i>
                </span>
                <span className="strelicaDesno">
                    <i className="fa fa-angle-right " aria-hidden="true" onClick = {(e)=>this.promeniSlikuDesno(e)}></i>
                </span>
            </div>
            </>
	    );
	}
}

export default Galerija;
