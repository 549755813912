import React, {Component} from 'react';
// import Header from './components/Header';
import Headernovi from './components/Headernovi';
// import LandingPage from './components/LandingPage';
import Footer from './components/Footer';
import './App.css';


class App extends Component {
  render(){
    return (
      <div className="container">
        {/* <Header /> */}
        <Headernovi />
        {/* <LandingPage /> */}
        <Footer />
      </div>
    );
  }
}

export default App;