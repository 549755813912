import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';
import UslugeStavkeRadnje from './UslugeStavkeRadnje.js';
import Porucivanje from './Porucivanje.js';
import CenovnikRadnja from './CenovnikRadnja.js';
import Parser from 'html-react-parser';


class UslugeRadnje extends Component {
	constructor(){
		super();
		this.state = { usluge: [], uslugaId:0, greska:''};
		this.ucitaneUsluge = this.ucitaneUsluge.bind(this);
	}
    ucitaneUsluge(resp){
		console.log(resp);
    	if(resp)
            if(resp.OKERR){
                let uslugaId = resp.lines[0].id;
                console.log("ggggggggggggggggggggggggggggg",uslugaId);
                this.setState({ usluge: resp.lines, greska:'', uslugaId:uslugaId  });
            }else 
        		this.setState({ greska:[resp.msg]})
        else
        	this.setState({ greska:'resp je undefined'})
    }

    componentDidMount(){
        let tip = this.props.match.params.tip;
        console.log("ooooooooooooooooooooooo" + tip);
        var that = this;

        ajaxCall('jinxapi/model/stavke_radnje/table', 'GET', {
            whereCols: [ { colName: 'stavke_radnje_field_tip', colVal: tip, oper:'=', logicOper: 'AND' }]
        }
        ,that.ucitaneUsluge);
    }
	render() {
        let usluge = []; 
		if(this.state.greska !== ''){
            usluge = this.state.greska;
        }else
			if(this.state.usluge.length > 0){
                usluge = this.state.usluge.map(u => {
                    let rec = u.naslov.split(' ');
                    let prvaRec = rec[0].toLowerCase();
                    if(u.tip == "poruci-fotografije") return <Porucivanje />
                    else{
                        if(u.tip == "cenovnik") return <CenovnikRadnja />
                        else{
                            return(
                                <>
                                    <h1 className="font1"><span >| </span>{prvaRec} <span> {rec[1]}</span></h1>
                                    <p className="">{Parser(u.duzi_tekst)}</p>
                                    <UslugeStavkeRadnje uslugaId={this.state.uslugaId} uslugaTip={this.props.match.params.tip}/>
                                </>
                            )
                        }
                    }
                })
            };
	    return (
            <div id="" className="container materijal">
                {usluge}
            </div>
	    );
	}
}

export default UslugeRadnje;
