import React from 'react';

function Footer(){
    return (
      <>        
        <div id="footer" className="container">
          <div className="py-3 social-networks">
            <a href="https://www.instagram.com/nikolic.photography/?hl=en" target="_blank">
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </a>
            <a href="https://www.facebook.com/gorannikolic.photography/" target="_blank">
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </a>
          </div>
          <p className="velika py-2">Pratite nas na društvenim mrežama i budite u toku sa najnovijim akcijama!</p>
          <p className="py-2">copyright 2020 - sva prava zadržana</p>
          <p className="iwanna ">
            <span>|</span>design <span>BY</span> {'\u00A0'}<a href="mailto:iwanna.solutions@gmail.com" className=''>iwanna.solutions@gmail.com</a>
             {/* - {'\u00A0'}<a href="tel:+381655329671" className=''>065 532 96 71</a> */}
          </p>
        </div>
      </>
    );
}

export default Footer;
