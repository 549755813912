import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';

class MeniGalerije extends Component {
	constructor(){
		super();
		this.state = { stavkeGalerije: [], greska:'' };
		this.ucitaneStavkeGalerije = this.ucitaneStavkeGalerije.bind(this);
	}
    ucitaneStavkeGalerije(resp){
		console.log(resp);
    	if(resp)
            if(resp.OKERR)
        		this.setState({ stavkeGalerije: resp.lines, greska:''  });
        	else 
        		this.setState({ greska:[resp.msg]})
        else
        	this.setState({ greska:'resp je undefined'})
    }

    componentDidMount(){
        ajaxCall('jinxapi/model/galerije/table', 'GET', {
            orderBy: {galerije_field_redosled: 'ASC'}
        }, this.ucitaneStavkeGalerije);
    }
	render() {
        let stavkeGalerije = [];
		if(this.state.greska !== '')
            stavkeGalerije = this.state.greska;
		else
			if(this.state.stavkeGalerije.length > 0)
            stavkeGalerije = this.state.stavkeGalerije.map(stavka => {
                        // let sl = "/images/" + foto.slika;
                            return(
                                <a key={stavka.tip} className="dropdown-item text-lowercase" href={"/foto-studio/" + stavka.tip}>{stavka.naslov}</a> 
                            )
                    });
	    return (
            <div className="meniSt dropdown-menu dropitem text-left " aria-labelledby="navbarDropdown">
                {/* <a id="linkS" className="dropdown-item font-weight-bold" href="/foto-studio">Fotografski studio</a> */}
                {stavkeGalerije}
                {/* <a className="dropdown-item font-weight-bold text-lowercase" href="/foto-studio-cenovnik">Cenovnik</a> */}
            
            </div>
	    );
	}
}

export default MeniGalerije;
