import React, {Component} from 'react';
import {ajaxCall} from './ajaxCall.js';
import Parser from 'html-react-parser';

class GalerijaStavkeRadnje extends Component {
	constructor(props){
		super(props);
		this.state = { fotografije: [], greska:''};
		this.ucitaneFotografije = this.ucitaneFotografije.bind(this);
	}
    ucitaneFotografije(resp){
		console.log(resp);
    	if(resp)
            if(resp.OKERR){
                this.setState({ fotografije: resp.lines, greska:''});
            }else 
        		this.setState({ greska:[resp.msg]})
        else
        	this.setState({ greska:'resp je undefined'})
    }
    componentDidMount(){
        ajaxCall('jinxapi/model/fotografije/table', 'GET', {
            whereCols: [ { colName: 'fotografije_field_galerija_id', colVal: this.props.stavkaUslugeId, oper:'=', logicOper: 'AND' }]
        },
        this.ucitaneFotografije);
    }
	render() {
        let fotografije = []; 
		if(this.state.greska !== ''){
            fotografije = this.state.greska;
        }else
			if(this.state.fotografije.length > 0){
                fotografije = this.state.fotografije.map(foto => {
                    let sl = "/images/radnja/" + foto.slika;
                        return(
                            <div key={foto.id} className="p-2"><img src={sl} alt={foto.alt} loading="lazy"/></div>
                        )
                });
            }
	    return (
        <div className="miniGalerija m-4">
            {fotografije}
        </div>
	    );
	}
}

export default GalerijaStavkeRadnje;
