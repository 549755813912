		function json2url(data){
    		return data2url(data, '').substr(1);
    	}
		function data2url(data, parent){
		    var str = '';
		    for(var key in data){
		        if(typeof data[key] === 'object'){
		            var p = (parent =='')? key : parent+'['+key+']';
		            str += data2url(data[key], p);
		        }else{
		            var k = encodeURIComponent(key);
		            var val = encodeURIComponent(data[key]);
		            str += (parent == '')? '&'+k+'='+val : '&'+parent+'['+k+']='+val;
		        }
		    }
		    return str;
		}


		export function ajaxCall(url, method, data, callback){
		    if(process.env.NODE_ENV == 'production')
				url = window.location.protocol + "//" + window.location.host + "/admin/" + url;
				//probaj i samo "/admin/" + url;
			else
				url = process.env.REACT_APP_folder + "/public/admin/" + url;

		    //     
		    if(method !== 'GET' && method !== 'POST' && data.jinxMethod === undefined)
		    	data.jinxMethod = method;
		    let nMethod = (method == 'GET')? method : 'POST'; 
		    let bData = {method: nMethod};

			let sData = JSON.stringify(data);
		    if(method == 'POST')
				bData = {
					method: nMethod,
				    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				    //credentials: 'same-origin', // include, *same-origin, omit
				    headers: {
				      'Content-Type': 'application/json'
				      // 'Content-Type': 'application/x-www-form-urlencoded',
				    },
				    body: sData // body data type must match "Content-Type" header
				};
			if(method == 'GET')
				url = url + ((sData == '{}')? '' : '?'+json2url(data));

		    console.log('request: '+url, sData);
			//return;
			fetch(url, bData)
				.then(resp => resp.text())
				.then(tData => {
					var rData;
	                if(tData && tData.trim() !== '')
	                    try{
	                        rData = JSON.parse(tData);
	                        errorReport(rData, 'green');
	                    }catch(e){
	                        errorReport('Cant parse to JSON: '+tData);
	                        //return;
	                    }
	                if(callback && typeof callback == 'function') 
	                    callback(rData);
				});
		}
		function errorReport(data, err){
			if(err)
				console.log('ajaxCallReport: ', data);
			else
				console.log('ERROR:', data);
		}